@import url("https://fonts.googleapis.com/css2?family=Saira&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: "Saira", sans-serif;
    transition: 0.3s ease;
    scroll-padding-top: 2rem;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

img {
    display: block;
    max-width: 100%;
}

.centralizer {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 15px;
}

.rotate {
    animation: spin 10s linear infinite;
}

.animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s ease-in-out forwards;
    animation-delay: 2000;
  }
  
  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body {
    overflow-x: hidden;
}