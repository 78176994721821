.react-3d-carousel {
  position: relative;
}
.react-3d-carousel .slider-container {
  position: absolute;
  left: 50%;
  top: 0%;
  width: 90%;
  margin: 0px auto 0px -45%;
}
.react-3d-carousel .slider-container .slider-content {
  position: relative;
  left: 50%;
  top: 0%;
  width: 70%;
  height: 80%;
  transform: translateX(-50%);
}
.react-3d-carousel .slider-container .slider-content .slider-single {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  transition: z-index 0ms 250ms;
}
.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  transition: 500ms cubic-bezier(0.17, 0.67, 0.55, 1.43);
  transform: scale(0);
  opacity: 0;
}
.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content img {
  max-width: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
}
.react-3d-carousel .slider-container .slider-content .slider-single.preactivede .slider-single-content {
  transform: translateX(-50%) scale(0);
}
.react-3d-carousel .slider-container .slider-content .slider-single.preactive {
  z-index: 1;
}
.react-3d-carousel .slider-container .slider-content .slider-single.preactive .slider-single-content {
  opacity: 0.3;
  transform: translateX(-25%) scale(0.8);
}
.react-3d-carousel .slider-container .slider-content .slider-single.proactive {
  z-index: 1;
}
.react-3d-carousel .slider-container .slider-content .slider-single.proactive .slider-single-content {
  opacity: 0.3;
  transform: translateX(25%) scale(0.8);
}
.react-3d-carousel .slider-container .slider-content .slider-single.proactivede .slider-single-content {
  transform: translateX(50%) scale(0);
}
.react-3d-carousel .slider-container .slider-content .slider-single.active {
  z-index: 2;
}
.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-left, .react-3d-carousel .slider-container .slider-content .slider-single.active .slider-right {
  display: block;
}
.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-single-content {
  opacity: 1;
  transform: translateX(0%) scale(1);
}
.react-3d-carousel .slider-container .slider-left {
  position: absolute;
  display: none;
  z-index: 3;
  right: 101%;
  margin-right: 5px;
  padding: 20px 15px;
  height: 100%;
  cursor: pointer;
}
.react-3d-carousel .slider-container .slider-left div {
  position: absolute;
  top: 50%;
  left: -50px;
  margin-top: -20px;
  color: #fff;
  transform: translateY(-50%);
  padding: 10px 10px;
}
.react-3d-carousel .slider-container .slider-right {
  display: none;
  position: absolute;
  z-index: 3;
  left: 101%;
  padding: 20px 15px;
  height: 101%;
  cursor: pointer;
  margin-left: -2px;
}
.react-3d-carousel .slider-container .slider-right div {
  position: absolute;
  top: 50%;
  left: 50px;
  margin-top: -20px;
  color: #fff;
  transform: translateY(-50%);
  padding: 10px 10px;
}
.react-3d-carousel .slider-container .slider-left-noborders {
  position: absolute;
  display: none;
  z-index: 3;
  right: 101%;
  margin-right: 5px;
  padding: 20px 15px;
  height: 100%;
  cursor: pointer;
}
.react-3d-carousel .slider-container .slider-left-noborders div {
  position: absolute;
  top: 50%;
  left: 0%;
  margin-top: -20px;
  color: #fff;
  transform: translateY(-50%);
  padding: 10px 10px;
}
.react-3d-carousel .slider-container .slider-right-noborders {
  display: none;
  position: absolute;
  z-index: 3;
  left: 101%;
  padding: 20px 15px;
  height: 101%;
  cursor: pointer;
  margin-left: -2px;
}
.react-3d-carousel .slider-container .slider-right-noborders div {
  position: absolute;
  top: 50%;
  left: 0%;
  margin-top: -20px;
  color: #fff;
  transform: translateY(-50%);
  padding: 10px 10px;
}
.react-3d-carousel .slider-container .slider-disabled div {
  display: none;
}
